import { MouseParallax, ScrollParallax } from 'react-just-parallax';
import Slider from 'react-slick';
import { Testimonial } from '~/types';
import { v4 as uuidv4 } from 'uuid';
import Markdown from "markdown-to-jsx";

type TestimonialSliderPropsType = {
    testimonials: Testimonial[];
    title?: string;
};

export const TestimonialSlider = (props: TestimonialSliderPropsType) => {
    const { testimonials, title } = props;
    function SampleNextArrow(props: { className: string; style: string; onClick: () => void }) {
        const { onClick } = props;
        return (
            <div className="hidden md:block">
                <div className="absolute z-10 -top-5 right-5 hover:cursor-pointer" onClick={onClick}>
                    <div className="transition-all duration-500 rotate-180 rounded-md fill-staff-300 dark:fill-white ease">
                        <svg width="34" height="28" viewBox="0 0 34 28" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M15.3839 0.616117C15.872 1.10427 15.872 1.89573 15.3839 2.38388L5.01777 12.75H32C32.6904 12.75 33.25 13.3096 33.25 14C33.25 14.6904 32.6904 15.25 32 15.25H5.01777L15.3839 
                  25.6161C15.872 26.1043 15.872 26.8957 15.3839 27.3839C14.8957 27.872 14.1043 27.872 13.6161 27.3839L1.11612 14.8839C0.627961 14.3957
                   0.627961 13.6043 1.11612 13.1161L13.6161 0.616117C14.1043 0.127961 14.8957 0.127961 15.3839 0.616117Z"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        );
    }
    function SamplePrevArrow(props: { className: string; style: string; onClick: () => void }) {
        const { onClick } = props;
        return (
            <>
                <div className="absolute z-10 hidden -top-5 right-20 hover:cursor-pointer md:block" onClick={onClick}>
                    <div className="transition-all duration-500 rounded-md fill-staff-300 dark:fill-white ease">
                        <svg width="34" height="28" viewBox="0 0 34 28" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M15.3839 0.616117C15.872 1.10427 15.872 1.89573 15.3839 2.38388L5.01777 12.75H32C32.6904 12.75 33.25 13.3096 33.25 14C33.25 14.6904 32.6904 15.25 32 15.25H5.01777L15.3839 
                  25.6161C15.872 26.1043 15.872 26.8957 15.3839 27.3839C14.8957 27.872 14.1043 27.872 13.6161 27.3839L1.11612 14.8839C0.627961 14.3957
                   0.627961 13.6043 1.11612 13.1161L13.6161 0.616117C14.1043 0.127961 14.8957 0.127961 15.3839 0.616117Z"
                            />
                        </svg>
                    </div>
                </div>
            </>
        );
    }

    const settings = {
        infinite: true,
        arrows: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        focusOnSelect: true,
        slidesToShow: 3,
        slidesToScroll: 2,
        infinate: true,
        initialSlide: 1,
        nextArrow: (
            <SampleNextArrow
                className={''}
                style={''}
                onClick={function (): void {
                    throw new Error('Function not implemented.');
                }}
            />
        ),
        prevArrow: (
            <SamplePrevArrow
                className={''}
                style={''}
                onClick={function (): void {
                    throw new Error('Function not implemented.');
                }}
            />
        ),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="md:py-64">
            <div>
                {title && (
                    <>
                        <span className="text-5xl font-bold text-staff-300 dark:text-white">{title}</span>
                    </>
                )}
                {!title && (
                    <>
                        <div className="relative z-10 w-full p-2 text-5xl font-bold text-transparent font-SourceSans dark:text-white bg-clip-text bg-gradient-to-r from-staff-390 to-staff-200 text-blue whitespace-nowrap md:text-8xl">
                            {'Testimonials'}
                        </div>
                        <ScrollParallax enableOnTouchDevice>
                            <div className="font-SourceSans font-bold before:absolute md:before:-top-[11rem] before:-top-[6rem]  before:left-8 md:before:left-10 before:-z-10 before:whitespace-nowrap before:text-7xl md:before:text-[154px] before:tracking-wide  before:text-staff-320 before:opacity-[0.5] before:content-['Testimonials']" />
                        </ScrollParallax>
                    </>
                )}
            </div>
            <div className="relative">
                <div className="relative" style={{ left: '-150px', bottom: '-100px' }}>
                    <MouseParallax shouldResetPosition enableOnTouchDevice isAbsolutelyPositioned zIndex={-2}>
                        <div
                            className="absolute top-0 hidden rounded-full lg:block h-60 w-60 bg-staff-370 dark:bg-staff-250"
                            style={{ zIndex: '-20' }}
                        />
                    </MouseParallax>
                </div>
                <Slider {...settings}>
                    {testimonials.map((item: Testimonial, i: number) => (
                        <div key={uuidv4()} className="px-5 my-10">
                            <div className="p-5 rounded-md shadow-md bg-staff-300 dark:bg-staff-420">
                                <div className="flex flex-wrap items-start gap-4 md:justify-center lg:flex-nowrap">
                                    <div className="flex-shrink-0 ">
                                        <svg
                                            className="rounded-full shadow-md"
                                            width="41"
                                            height="41"
                                            viewBox="0 0 41 41"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <image width="41" height="41" xlinkHref={item.attributes.Profile} />
                                        </svg>
                                    </div>

                                    <div className="text-white ">
                                        <span className="text-base font-SourceSans">{item.attributes.Name}</span>
                                        <div className="my-2 ">
                                            <Markdown  className="font-sans text-xs ">{`${item.attributes.Description.substring(
                                                0,
                                                130
                                            )}${item.attributes.Description.length > 130 ? ' ...' : ''}`}</Markdown>
                                        </div>

                                        <div className="flex flex-wrap ">
                                            {Array.from({ length: item.attributes.Stars }, (value, index) => index).map(
                                                (item, id) => (
                                                    <div key={id}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M10.868 2.8837C10.5469 2.11168 9.45329 2.11168 9.13219 2.8837L7.3014 7.28547L2.54932 7.66644C1.71586 7.73326 1.37791 8.77337 2.01291 9.31732L5.63349 12.4187L4.52735 17.056C4.33334 17.8693 5.21812 18.5121 5.93167 18.0763L10.0001 15.5913L14.0686 18.0763C14.7821 18.5121 15.6669 17.8693 15.4729 17.056L14.3667 12.4187L17.9873 9.31732C18.6223 8.77337 18.2844 7.73326 17.4509 7.66644L12.6988 7.28547L10.868 2.8837Z"
                                                                fill="#FFB800"
                                                            />
                                                        </svg>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};
