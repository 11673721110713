import { animated, useSpring } from "@react-spring/web";
import { Link } from "@remix-run/react";
import { useEffect, useState } from "react";
import { getJobs } from "~/routes/jobs";
import { Job } from "~/types";
import { JobCard } from "../organisms/JobCard";
const API_BASE_URL= "https://recsource.co.uk";

export const JobsSection = () => {
  const [featured, setFeatured] = useState<Job[]>([]);
  const [anim, setAnim] = useState(true);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      const { data } = await getJobs(1, 15);
      setFeatured(data);
    };

    fetch();
  }, []);

  useEffect(() => {
    if (featured.length) {
      const intervalId2 = setInterval(() => {
        const arr = [
          featured[featured.length - 1],
          ...featured?.slice(0, featured.length - 1),
        ];
        setFeatured(arr);
      }, 4000);

      setTimeout(function () {
        clearInterval(intervalId2);
      }, 5000);
    }
  }, [featured]);

  const props = useSpring({
    from: { scale: anim ? 1 : 1.05 },
    to: {
      scale: anim ? 1.05 : 1,
    },
    config: {
      duration: 1000,
    },
  });

  // useMemo(() => {
  //     setAnim(!anim);
  // }, [featured]);

  const handleShareClick = (props: any) => {
    const copyUrl = `${API_BASE_URL}/jobs/${encodeURIComponent(
      formatText(props.clientJobDescriptionName)
    )}?id=${props.clientJobDescriptionId}`;
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(copyUrl)
        .then(() => {
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 10000);
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } else {
      console.warn("Clipboard not supported");
    }
  };

  const formatText = (text = '') => text
    .trim()
    .toLowerCase()
    .replace(/[!@#$%^&*(),-/]/g, "") // Remove special characters
    .replace(/\s+/g, "-");

  return (
    featured.length > 0 &&( <div className="pl-8 sm:px-8 -mt-28 md:mt-64">
      <div className="relative z-10 flex items-center justify-between pr-8 my-5 md:pr-0 ">
        <span className="text-3xl font-bold text-black font-Gilroy dark:text-white">
          Latest jobs
        </span>
        <Link rel="canonical" prefetch="render" to="/jobs">
          <div className="font-bold underline text-l font-Gilroy text-staff-default hover:text-staff-light dark:hover:text-staff-dark">
            See all
          </div>
        </Link>
      </div>

      <animated.ul className="gap-5 md:grid md:grid-cols-3">
        <animated.li style={props} className="hidden md:block md:col-span-1">
          <div className="flex flex-col justify-between h-full p-5 border rounded-md border-staff-320 dark:bg-white">
            <div className="flex flex-col justify-start p-5 ">
              <div className="flex justify-between">
                <div
                  className="text-2xl font-semibold text-staff-300 font-SourceSans"
                  style={{
                    width: "80%",
                    textOverflow: "ellipsis",
                    wordBreak: "break-word",
                  }}
                >
                  {featured[0]?.clientJobDescriptionName}
                  <br className="hidden md:flex" />
                </div>
                <span className="text-xl font-normal text-staff-170 font-SourceSans">
                  {featured[0]?.salary}
                </span>
              </div>
              <div className="flex justify-start my-4 space-x-3">
                <div className="flex space-x-2 place-content-start ">
                  <svg
                    width={13}
                    height={15}
                    viewBox="0 0 13 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.17399 14.832C6.19538 14.8444 6.2122 14.854 6.22411 14.8607L6.24389 14.8717C6.40175 14.9584 6.59758 14.9577 6.7556 14.872L6.77589 14.8607C6.7878 14.854 6.80462 14.8444 6.82601 14.832C6.8688 14.8072 6.92994 14.771 7.00681 14.7237C7.1605 14.6291 7.37745 14.4895 7.6366 14.3065C8.15402 13.9409 8.84441 13.3986 9.5363 12.6902C10.9134 11.2803 12.3438 9.16186 12.3438 6.4375C12.3438 3.21009 9.72741 0.59375 6.5 0.59375C3.27259 0.59375 0.65625 3.21009 0.65625 6.4375C0.65625 9.16186 2.0866 11.2803 3.4637 12.6902C4.1556 13.3986 4.84598 13.9409 5.3634 14.3065C5.62255 14.4895 5.8395 14.6291 5.99319 14.7237C6.07006 14.771 6.1312 14.8072 6.17399 14.832ZM6.5 8.5625C7.6736 8.5625 8.625 7.6111 8.625 6.4375C8.625 5.2639 7.6736 4.3125 6.5 4.3125C5.3264 4.3125 4.375 5.2639 4.375 6.4375C4.375 7.6111 5.3264 8.5625 6.5 8.5625Z"
                      fill="#031A38"
                    />
                  </svg>
                  <span className="text-sm font-normal text-staff-420 font-Gilroy">
                    {featured[0]?.locationName}
                  </span>
                </div>
                <div className="flex space-x-2 place-content-start ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2 7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z"
                      fill="#031A38"
                    ></path>
                  </svg>
                  <span className="text-sm font-normal text-staff-420 font-Gilroy">
                    {featured[0]?.categoryName}
                  </span>
                </div>
              </div>
              <div className="mb-20 ">
                <span className="text-lg font-normal text-staff-300 font-SourceSans">
                  {featured[0]?.shortJobDescription}
                </span>
              </div>
            </div>

            <div className="flex gap-5">
            <a
              href={featured[0]?.jobUrl}
              className={`${"w-full text-center mt-5" 
                } font-normal text-white font-SourceSans  bg-staff-400 hover:bg-staff-420 rounded-3xl px-5 py-2 block`}
            >
              Apply for this job
            </a>
            <button
              onClick={() => handleShareClick(featured[0])}
              className={`${"w-full text-center mt-5"
                } font-normal text-white font-SourceSans bg-staff-400 hover:bg-staff-420 rounded-3xl px-5 py-2 block`}
            >
              Share
            </button>

            {isCopied && (
              <span className="text-green-500 text-sm mt-2">
                Copied to clipboard!
              </span>
            )}
          </div>
          </div>
        </animated.li>
        <animated.li className="md:col-span-2">
          <div className="flex justify-between w-full h-full gap-5 snap-center overflow-scrollJob sm:grid sm:grid-cols-2 ms-3">
            <JobCard hidden {...featured[1]}  shareUrl={API_BASE_URL} />
            <JobCard hidden {...featured[2]} />
            <JobCard hidden {...featured[4]} />
            <JobCard hidden {...featured[3]} />
          </div>
        </animated.li>
      </animated.ul>
    </div>)
  );
};
