import { useState } from 'react';

import { json } from '@remix-run/node';
import type { LoaderFunction } from '@remix-run/node';
import { Link, useLoaderData } from '@remix-run/react';
import Markdown from 'markdown-to-jsx';
import { useCountUp } from 'react-countup';
import { MouseParallax, ScrollParallax } from 'react-just-parallax';

import { JobsSection } from '~/components/jobsSection/jobsSection';
import { TestimonialSlider } from '~/components/organisms/TestimonialSlider';
import { getTestinomials, getHomePageData } from '~/modules/queries';
import { getGlobalData } from '~/modules/queries/global.server';
import { getHelpedNumbers } from '~/modules/queries/helpednumbers.server';
import type { HelpedNumber, HeroBgImage, HomePageData, LoaderData } from '~/types';


import { ContactUs } from '../components/contactus';

export const loader: LoaderFunction = async () => {
    //const jobs_data = await getJobs();
    const testinomials_data = await getTestinomials();
    const helpedNumbers_data = await getHelpedNumbers();
    const homePageData = await getHomePageData();
    const globalSetting = await getGlobalData();
    return json<LoaderData>({
        helpedNumbers: helpedNumbers_data.data,
        jobs: [],
        testinomials: testinomials_data.data,
        homePageData: homePageData,
        GOOGLE_API_KEY: process.env.GOOGLE_API_KEY,
        globalSettingData: globalSetting,
    });
};

export default function IndexPage() {
    const { jobs, testinomials, helpedNumbers, homePageData, globalSettingData } = useLoaderData();
    return (
        <>
            {globalSettingData && <HeroImg globalSettingData={globalSettingData.data.attributes.heroBgImages} />}
            <Hero />
            <div className="mx-auto max-w-7xl">
                <JobsSection />
            </div>
            {homePageData && (
                <div className="relative my-20 lg:my-none">
                    <AboutUs helpedNumbers={helpedNumbers} homePageData={homePageData} />
                </div>
            )}
            <div className="mx-auto max-w-7xl">
                <TestimonialSlider testimonials={testinomials} />
                <ContactUs Heading="Let's Talk" BtnText="Contact us" Shadow={true} />
            </div>
        </>
    );
}

function HeroImg({ globalSettingData }: { globalSettingData: HeroBgImage; }) {
    const desktopLightImg = globalSettingData.desktopImgLightMode.data.attributes.url;
    const desktopDarkImg = globalSettingData.desktopImgDarkMode.data.attributes.url;
    const mobileDarkImg = globalSettingData.mobileImgDarkMode.data.attributes.url;
    const mobileLightImg = globalSettingData.mobileImgLightMode.data.attributes.url;
    return (
        <>
            <div className="w-full md:hidden">
                <img
                    src={mobileLightImg}
                    height="100%"
                    alt="hero_img_light_mobile"
                    width="100%"
                    className="absolute top-0 left-0 dark:hidden -z-10"></img>
                <img
                    src={mobileDarkImg}
                    height="100%"
                    alt="hero_img-dark_mobile"
                    width="100%"
                    className="absolute top-0 left-0 hidden dark:block -z-10"></img>
            </div>

            <div className="hidden w-full md:block">
                <img
                    src={desktopLightImg}
                    height="100%"
                    alt="hero_img_light"
                    width="100%"
                    className="absolute top-0 left-0 dark:hidden -z-10"></img>
                <img
                    src={desktopDarkImg}
                    height="100%"
                    alt="hero_img-dark"
                    width="100%"
                    className="absolute top-0 left-0 hidden dark:block -z-10"></img>
            </div>
        </>
    );
}
const Hero = () => {
    const [currentText, setCurrentText] = useState('life');
    const intervalId = setInterval(() => {
        if (currentText === 'life') {
            setCurrentText('work');
        } else if (currentText === 'work') {
            setCurrentText('choice');
        } else {
            setCurrentText('life');
        }
    }, 2000);
    setTimeout(function () {
        clearInterval(intervalId);
    }, 3000);

    return (
        <>
            <div className="relative px-6 mx-auto py-28 md:px-0 max-w-7xl">
                <MouseParallax shouldResetPosition enableOnTouchDevice isAbsolutelyPositioned zIndex={-2}>
                    <div
                        className="absolute top-0 rounded-full lg:block h-60 w-60 bg-staff-370 dark:bg-staff-250"
                        style={{ zIndex: '-20' }}
                    />
                </MouseParallax>
                <div className="pb-20 mx-auto">
                    <div className="relative md:mt-32">
                        <ScrollParallax enableOnTouchDevice>
                            <div>
                                <span className="text-5xl font-bold text-transparent font-Gilroy md:text-8xl bg-gradient-to-r from-staff-390 to-staff-200 dark:from-staff-420 bg-clip-text">
                                    Your
                                    <span className="px-3 ml-5 text-white transition duration-700 ease-in-out bg-staff-420 rounded-2xl">
                                        {currentText}
                                    </span>
                                </span>
                            </div>
                            <div className="lg:mt-[250px]">
                                <Link
                                    rel="canonical"
                                    prefetch='render'
                                    to="/jobs"
                                    className="px-8 py-6 text-xl font-normal text-white rounded-full hover:bg-staff-420 bg-staff-400 font-SourceSans">
                                    See the Jobs
                                </Link>
                            </div>
                        </ScrollParallax>
                    </div>
                    <MouseParallax enableOnTouchDevice isAbsolutelyPositioned zIndex={-2}>
                        <div
                            className=" hidden lg:block  h-96 w-96 bg-staff-370 dark:bg-staff-250  rounded-full absolute right-[40%] top-96 -z-10   "
                            style={{ zIndex: '-20' }}
                        />
                    </MouseParallax>
                </div>
            </div>
        </>
    );
};

const AboutUs = ({ helpedNumbers, homePageData }: { helpedNumbers: HelpedNumber[]; homePageData: HomePageData; }) => (
    <div className="lg:px-8 my-10 lg:mt-[300px]  max-w-7xl mx-auto ">
        <div className="z-10 flex flex-col items-center justify-between gap-5 lg:gap-10 lg:flex-row">
            <div className="w-full px-8 lg:hidden">
                <Heading heading={homePageData.data.attributes.AboutUs[0].Heading} />
            </div>
            <HelpedNumbersRing helpedNumbers={helpedNumbers} />
            <div className=" lg:w-1/2">
                <div className="hidden mt-16 lg:block">
                    <div className="hidden w-full md:block">
                        <Heading heading={homePageData.data.attributes.AboutUs[0].Heading} />
                    </div>
                    <div>
                        <span className="text-base font-normal text-left font-SourceSans lg:text-lg text-staff-300 dark:text-white">
                            <Markdown>{homePageData.data.attributes.AboutUs[0].Description}</Markdown>
                        </span>
                    </div>
                    <div className="mt-6 mb-3">
                        <a
                            href={homePageData.data.attributes.AboutUs[0].ButtonLink}
                            className="px-6 py-3 font-normal text-white hover:bg-staff-410 bg-staff-360 dark:hover:bg-staff-420 rounded-3xl w-max font-SourceSans">
                            {homePageData.data.attributes.AboutUs[0].ButtonText}
                        </a>
                    </div>
                </div>
            </div>
            <div className="px-8 mt-8 mb-16 lg:hidden">
                <div>
                    <span className="text-base font-normal text-left font-SourceSans md:text-lg text-staff-300 dark:text-white">
                        <Markdown>{homePageData.data.attributes.AboutUs[0].Description}</Markdown>
                    </span>
                </div>
                <div className="mt-5">
                    <a
                        href={homePageData.data.attributes.AboutUs[0].ButtonLink}
                        className="px-4 py-3 mt-4 text-white bg-black rounded-3xl dark:bg-staff-420">
                        {homePageData.data.attributes.AboutUs[0].ButtonText}
                    </a>
                </div>
            </div>
        </div>
    </div>
);

export const HelpedNumbersRing = (props: { hideImages?: boolean; helpedNumbers: HelpedNumber[]; }) => {
    useCountUp({
        ref: 'candidates',
        start: props.helpedNumbers[0].attributes.Number / 3,
        end: props.helpedNumbers[0].attributes.Number,
        enableScrollSpy: true,
        scrollSpyDelay: Math.random() * 500,
        useEasing: true,
    });
    useCountUp({
        ref: 'clients',
        start: props.helpedNumbers[1]?.attributes?.Number / 3,
        end: props.helpedNumbers[1]?.attributes?.Number,
        enableScrollSpy: true,
        scrollSpyDelay: Math.random() * 500,
        useEasing: true,
    });

    return (
        <div className="flex flex-row items-center justify-between w-full pt-5 lg:pt-0 lg:justify-center lg:w-1/2">
            {!props?.hideImages && (
                <>
                    <div className="flex-col hidden gap-4 lg:flex">
                        <div className="absolute top-[0%] left-0">
                            <img src="/useimg.svg" width="100%" alt="left-img" />
                        </div>
                        <svg
                            className="absolute top-[50%] left-0"
                            xmlns="http://www.w3.org/2000/svg"
                            width="244"
                            height="343"
                            viewBox="0 0 244 343"
                            fill="none">
                            <path d="M172.513 0L244 126.398L-123.513 343L-195 216.602L172.513 0Z" fill="#E5F0F7" />
                        </svg>
                    </div>
                    <div className="flex flex-col w-full lg:hidden">
                        <div className="">
                            <img src="/Vector (3).png" width="100%" alt="left-img" />
                        </div>
                        <svg
                            className="fill-staff-370 dark:fill-staff-540 "
                            xmlns="http://www.w3.org/2000/svg"
                            width="164"
                            height="198"
                            viewBox="0 0 164 198"
                            fill="none">
                            <path d="M122.638 0L164 72.9646L-48.6383 198L-90 125.036L122.638 0Z" />
                        </svg>
                    </div>{' '}
                </>
            )}
            <div className="relative flex flex-col justify-end w-full gap-5 md:gap-10 lg:gap-14">
                <div className="flex flex-col items-center justify-center w-full">
                    <div className="font-bold text-center text-staff-360    md:text-[64px] text-5xl font-Gilroy">
                        +
                        <span id="candidates" />
                    </div>
                    <div className="pt-5 text-base font-bold text-start text-staff-300 dark:text-staff-360 md:text-lg font-Gilroy">
                        {props.helpedNumbers[0].attributes.Title}
                    </div>
                </div>
                <div className="flex flex-col items-center justify-center w-full">
                    <div className="font-bold text-center text-staff-330   md:text-[64px] text-5xl font-Gilroy">
                        +
                        <span id="clients" />
                    </div>
                    <div className="pt-5 text-base font-bold text-start text-staff-330 md:text-lg font-Gilroy">
                        {props.helpedNumbers[1]?.attributes?.Title}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const HelpedNumbersRing2 = (props: { hideImages?: boolean; helpedNumbers: HelpedNumber[]; }) => {
    useCountUp({
        ref: 'candidates',
        start: props.helpedNumbers[0].attributes.Number / 3,
        end: props.helpedNumbers[0].attributes.Number,
        enableScrollSpy: true,
        scrollSpyDelay: Math.random() * 500,
        useEasing: true,
    });

    useCountUp({
        ref: 'clients',
        start: props.helpedNumbers[1]?.attributes?.Number / 3,
        end: props.helpedNumbers[1]?.attributes?.Number,
        enableScrollSpy: true,
        scrollSpyDelay: Math.random() * 500,
        useEasing: true,
    });

    return (
        <div className="flex flex-row justify-start w-full gap-10 py-10 md:flex-col md:justify-center md:gap-14">
            <div className="flex flex-col items-center justify-center w-full">
                <div className="font-bold text-center text-staff-360 md:text-[64px] text-5xl font-Gilroy">
                    +<span id="candidates" />
                </div>
                <div className="pt-5 text-base font-bold text-center text-staff-300 dark:text-white md:text-lg font-Gilroy">
                    {props.helpedNumbers[0]?.attributes.Title}
                </div>
            </div>
            <div className="flex flex-col items-center justify-center w-full">
                <div className="font-bold text-center text-staff-330   md:text-[64px] text-3xl font-Gilroy">
                    +<span id="clients" />
                </div>
                <div className="pt-5 text-base font-bold text-center text-staff-330 md:text-lg font-Gilroy">
                    {props.helpedNumbers[1]?.attributes.Title}
                </div>
            </div>
        </div>
    );
};

function Heading({ heading }: { heading: string; }) {
    return (
        <>
            <div className="w-full text-start">
                <div className="relative z-10 w-full p-2 text-5xl font-bold text-transparent font-SourceSans dark:text-white bg-clip-text bg-gradient-to-r from-staff-390 to-staff-200 text-blue whitespace-nowrap md:text-8xl">
                    {heading}
                </div>
                <ScrollParallax enableOnTouchDevice>
                    <div className="font-SourceSans  font-bold absolute md:-top-[11rem] -top-[6rem]  left-8 md:left-10 -z-10 whitespace-nowrap text-7xl md:text-[154px] tracking-wide  text-staff-320 opacity-[0.5]">
                        {heading}
                    </div>
                </ScrollParallax>
            </div>
        </>
    );
}
